import './crystal-pack-form.scss';
import WithRender from './crystal-pack-form.html';

import { Component, Vue, Prop } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import { ServerApiNamings } from 'server-api/server-api-namings';

class CrystalPackModel implements IBuyCrystalPackRequest {
	public crystalPackId?: string | null = null;
	public customCount?: number | null = null;
}

export const CrystalPackFormComponentName = 'PnzCrystalPackForm';
@WithRender
@Component({
	name: CrystalPackFormComponentName
})
export class CrystalPackForm extends BootstrapFormContainer<CrystalPackModel> {
	public model: CrystalPackModel = new CrystalPackModel();
	public wsCrystalPackControllerService: IWsCrystalPackControllerService;
	public dsCrystalPackControllerService: IDsCrystalPackControllerService;
	public get rules() {
		return {};
	}
	public list!: ICrystalPackResponse[];

	public crystalUrl = require('./crystal2.png');

	public customCountOptions = { min: 1 };

	public isLoadingPack: { [key: string]: boolean } | null = null;

	public initialized = false;
	protected processSubmit(destroyPromise: Promise<void>): Promise<any> {
		return this.wsCrystalPackControllerService.purchaseCrystalPack(this.model, destroyPromise);
	}

	public buy(id: string) {
		this.isLoading = true;
		this.isLoadingPack = { [id]: true };
		this.wsCrystalPackControllerService.purchaseCrystalPack({ crystalPackId: id }, this.destroyPromise).finally(() => {
			this.isLoadingPack = null;
			this.isLoading = false;
		});
	}

	public mounted() {
		this.dsCrystalPackControllerService.getList().then(list => {
			this.list = list.data;
			this.initialized = true;
		});
	}
	constructor() {
		super();
		this.wsCrystalPackControllerService = this.get<IWsCrystalPackControllerService>(
			ServerApiNamings.wsCrystalPackControllerServiceName
		);
		this.dsCrystalPackControllerService = this.get<IDsCrystalPackControllerService>(
			ServerApiNamings.dsCrystalPackControllerServiceName
		);
	}
}
