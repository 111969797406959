import './crystal-pack-dialog.scss';
import WithRender from './crystal-pack-dialog.html';
import { ModalPlugin } from 'bootstrap-vue';

import { Component, Vue, Prop } from 'vue-property-decorator';
import { UIComponent } from 'ui-controls-core/ui-component';
import { CrystalPackForm } from './crystal-pack-form/crystal-pack-form';

Vue.use(ModalPlugin);

export const CrystalPackDialogComponentName = 'PnzCrystalPackDialog';

@WithRender
@Component({
	name: CrystalPackDialogComponentName,
	components: {
		'pnz-crystal-pack-form': CrystalPackForm
	}
})
export class CrystalPackDialog extends UIComponent {
	constructor() {
		super();
	}
	public noCloseOnBackdrop = false;

	public created() {
		$(document).on('mouseup', () => {
			setTimeout(() => {
				this.noCloseOnBackdrop = false;
			}, 500);
		});
		$(document).on('dragend', () => {
			setTimeout(() => {
				this.noCloseOnBackdrop = false;
			}, 500);
		});
	}

	public onMouseDown() {
		Vue.set(this, 'noCloseOnBackdrop', true);
	}

	public onCloseClick() {
		if (this.$route.params.closeUrl) {
			this.$router.push(this.$route.params.closeUrl);
		}
	}

	public onHide() {
		if (this.$route.params.closeUrl) {
			this.$router.push(this.$route.params.closeUrl);
		}
	}
}
