<b-modal :visible="true" :hide-header="true" :hide-footer="true" size="lg" modal-class="pnz-purchase-crystal-pack-dialog" @hide="onHide" :no-close-on-backdrop="noCloseOnBackdrop">
	<pnz-panel class="pnz-purchase-crystal-pack-panel">
		<template slot="panel-header">
			{{ 'Ws_GameClient_Payment_PurchaseCrystalPackDialog_Title' | localize }}
		</template>
		<template slot="panel-toolbar-right">
			<pnz-close-button v-on:click.prevent="onCloseClick"></pnz-close-button>
		</template>
		<template slot="panel-body">
			<pnz-crystal-pack-form />
		</template>
	</pnz-panel>
</b-modal>
