<div v-if="initialized">
	<pnz-form id="pnz-purchase-crystal-pack-form">
		<template v-for="item in list">
			<pnz-form-row>
				<pnz-button class="pnz-crystal-pack-button" @click="buy(item.id)" :is-loading="isLoadingPack && isLoadingPack[item.id]">
					<h6 class="pnz-pack">
						<span class="pnz-pack-text">
							{{ item.count }} &nbsp;
						</span>
						<img :src="crystalUrl" class="pnz-pack-image" />
					</h6>
				</pnz-button>
			</pnz-form-row>
			<pnz-form-row>
				<pnz-empty-row />
			</pnz-form-row>
		</template>
		<!-- <pnz-form-row>
			<pnz-form-group-with-error-block :input-options="customCountOptions" :hide-validation="true" col-sm="12" type="number" field="customCount" :label="'Ws_GameClient_Payment_PurchaseCrystalPackDialog_CustomAmount' | localize" />
		</pnz-form-row> -->
		<!-- <pnz-form-row>
			<pnz-button type="submit" :is-loading="isLoading && !isLoadingPack">{{ 'buy' | localize }}</pnz-button>
		</pnz-form-row> -->
		<pnz-form-row>
			<pnz-empty-row />
		</pnz-form-row>
	</pnz-form>
</div>
<div v-else class="text-center">
	<div class="spinner-border text-light" role="status">
		<span class="sr-only">Loading...</span>
	</div>
</div>
